import React from "react"
import { Link } from "gatsby"

import Navbar from "../Navbar"
import Footer from "../Footer"

import "./layout.scss"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`

  return (
    <>
      <Navbar />
      <main className="container">{children}</main>
      <Footer />
    </>
  )
}

export default Layout
