import React from "react"
import { Link } from "gatsby"

import "./header.scss"

const Header = ({ title, subtitle, isPost }) => {
  return (
    <header>
      <div className="container title">
        <h1>{title}</h1>
        <p className={(isPost)? "small" : ""}>{subtitle}</p>
      </div>
    </header>
  )
}

export default Header
