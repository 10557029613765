import React from "react"
import { Link } from "gatsby"

import "./footer.scss"

const Footer = () => {
  return (
    <footer>
      <div className="custom-shape-divider-top-1595697590">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>
      <div className="container">
        <ul>
        <li>
            <Link to="/tags/dica">Dicas</Link>
          </li>
          <li>
            <Link to="/tags/resenha">Resenhas</Link>
          </li>
          <li>
            <Link to="/tags/to-be-read">
              To be read <span className="d-sm-none">(TBR)</span>
            </Link>
          </li>
          <li>
            <Link to="/tags/lista">Listas de livros</Link>
          </li>
        </ul>

        <div className="credits">
          <ul>
            <li>
              <Link to="/sobre">Sobre & Conato</Link>
            </li>
            <li>
              <a href="#prof">Site profissional</a>
            </li>
            <li>
              <a href="#prof">Twitter</a>
            </li>
          </ul>
          <p>
            Todas as fotos e textos publicados são produzidos por Flavia Nunes,
            dona do blog, exceto quando sinalizado. Todos os direitos reservados
            © 2020
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
