import React from "react"
import { Link } from "gatsby"

import "./navbar.scss"

const Navbar = () => {
  return (
    <div className="navbar">
      <nav className="container">
        <div className="brand">
          <Link to="/">Leituras</Link>
        </div>

        <ul>
          <li>
            <Link to="/sobre">Sobre</Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default Navbar
